<template>
  <div class="noDataWrapper">
    <div class="text">
      <div id="checkImg">
        <img src="../assets/icons/error.svg" />
      </div>
      <p id="registerThanks">{{ $t('not_active_account.title') }}</p>
      <p id="infoText">{{ $t('not_active_account.body') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotActiveAccount",
  components: {},
  data() {
    return {};
  }
};
</script>

<style scoped>
.noDataWrapper {
  background-color: white;
  box-shadow: 0px 1px 2px 0px #ccd0db;
  width: 35vw;
  height: fit-content;
  padding: 2rem 1rem;
  align-self: center;
  margin: 5% auto auto auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  width: 50%;
}
#checkImg img {
  height: 49px;
  width: 49px;
}
#registerThanks {
  font-size: 1.5rem;
  font-weight: 500;
}
@media screen and (max-width: 1024px) {
  .noDataWrapper {
    width: fit-content;
    padding: 5% 8%;
    margin: 6%;
  }
}
@media screen and (max-width: 450px) {
  .noDataWrapper {
    padding: 1rem;
  }
  .text {
    width: 100%;
  }
}
</style>